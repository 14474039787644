var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("graphTitle", {
        staticClass: "paddingLR20 paddingT10",
        attrs: { title: "故障数量统计" },
      }),
      _c("div", { staticClass: "faultNumber", attrs: { id: "faultNumber" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }